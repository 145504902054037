import { AssignmentPanel, AssignmentTable } from "dtos";
import { getInspectionStatusLabel, getInspectionStatusNLabel } from "helpers/AssignmentUtils";
import { selectedTable } from "myrecoil/AssignmentState";
import { useRecoilState } from "recoil";
import PanelsDetails from "./PanelsDetails";

interface Props {
	imgPrefix?: string;
	updateTable: (tableWrapper: {
		table: AssignmentTable;
		area: number;
		row: number;
		col: number;
	}) => void;
}


const TableDetails: React.FC<Props> = ({ imgPrefix, updateTable }) => {
	const [table] = useRecoilState(selectedTable);

	const round = (num: number) => {
		return Math.round(num * 100) / 100;
	};

	return (
		<>
			{table && (
				<div>
					<span
						style={{
							fontSize: "1.25rem",
							marginRight: "5px",
						}}
					>
						<b>Lat: </b>
						{table.table.gps_Location.lat}{" "}
						<p style={{ display: "inline-block" }}>
							<b>Lng: </b>
							{table.table.gps_Location.lng}
						</p>
					</span>
					<p
						style={{
							fontSize: "1.25rem",
							marginRight: "5px",
						}}
					>
						<b>Table inclination: </b>
						{round(table.table.inclination_Deg)}&deg;
					</p>
					<p
						style={{
							fontSize: "1.25rem",
							marginRight: "5px",
						}}
					>
						<b>Panels inspection status: </b>
						<span
							className={`tag is-outlined is-light is-large ${
								getInspectionStatusNLabel(table.table) === 0
									? "is-primary"
									: "is-danger"
							}`}
						>
							{getInspectionStatusNLabel(table.table)} damaged panels
						</span>
					</p>
					{table.table.ir_Images === 0 &&
					table.table.rgb_Images === 0 ? (
						<div>
							<span
								style={{
									marginBottom: "0.5em",
									paddingLeft: "0px",
								}}
								className={`tag is-outlined is-light is-large is-danger`}
							>
								Images aren't uploaded yet.
							</span>
						</div>
					) : (
						<div>
							{table.table.rgb_Images > 0 && (
								<img
									alt=""
									src={`${imgPrefix}/table_${
										table.table.id
									}_RGB_${table.table.rgb_Images - 1}.png`}
								/>
							)}
							{table.table.ir_Images > 0 && (
								<img
									alt=""
									src={`${imgPrefix}/table_${
										table.table.id
									}_IR_${table.table.ir_Images - 1}.png`}
								/>
							)}
						</div>
					)}
					{table.table.status !== "ABANDONED" && (<PanelsDetails
						key={table.table.id}
						imgPrefix={imgPrefix!}
						panels={table.table.panels}
						panelsLayout={table.table.panelsLayout}
						updatePanel={(panel: AssignmentPanel) => {
							let damagedPanels = 0;
							let panels = table.table.panels.map((pan) => {
								if (pan.id == panel.id)
								{
									pan.inspectionStatus = panel.inspectionStatus;
								}
								if (pan.inspectionStatus !== "Ok" && pan.inspectionStatus === "NotInspected")
								{
									damagedPanels++;
								}

								return pan;
							});
							let newTable = {
								...table,
								table: {
									...table.table,
									panels,
									//inspectionStatus:
									//	damagedPanels > 0 ? "Problem" : "Ok",
									panels_Failed: damagedPanels,
								},
							};
							updateTable(newTable);
						}}
					></PanelsDetails>)}
				</div>
			)}
		</>
	);
};

export default TableDetails;

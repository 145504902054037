import { useApi } from "api/API";
import AssignmentMap from "components/assignment/AssignmentMap";
import TableDetails from "components/assignment/TableDetails";
import Box from "components/Box";
import Spinner from "components/Spinner";
import { useMediaQuery } from "customhooks/useMediaQuery";
import useMount from "customhooks/useMount";
import { AssignmentTable, Mission } from "dtos";
import { Assignment, SiteInfo,WeatherInfo } from "dtos/Assignment";
import {
	loadingAssignmentAtom,
	loadingAssignmentErrorAtom,
} from "myrecoil/AssignmentState";
import { useEffect, useState } from "react";
import { FaAngleLeft } from "react-icons/fa";
import { useHistory, useParams } from "react-router";
import { useRecoilState, useSetRecoilState } from "recoil";
import { selectedTable as selectedTableAtom } from "myrecoil/AssignmentState";
import TableNav from "components/assignment/TableNav";
import ReportActions from "components/assignment/ReportActions";
import { Losses, getByInspectionStatus } from "helpers/AssignmentUtils";
import SetPowerPerAssignmentModal from "components/assignment/SetPowerPerAssignment";
import SetWeatherPerAssignmentModal from "components/assignment/SetWeatherPerAssignment";

const TABLE_IMAGES_URL = "https://images.moonlimit.com";

const AssignmentView: React.FC = () => {
	let history = useHistory();
	const breakpoints = useMediaQuery();
	const { id } = useParams<{ id: string | undefined }>();
	const [assignment, setAssignment] = useState<Assignment>();
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<string | null>(null);
	const [mission, setMission] = useState<Mission | null>(null);
	const [curAssignment, setCurAssignment] = useState<Assignment | null>( null	);
	const [curWAssignment, setCurWAssignment] = useState<Assignment | null>( null	);

	const setLoadingAssignment = useSetRecoilState(loadingAssignmentAtom);
	const setLoadingAssignmentError = useSetRecoilState(
		loadingAssignmentErrorAtom
	);
	const [selectedTable, setSelectedTable] = useRecoilState(selectedTableAtom);

	const api = useApi();

	const savePower = (assignment: Assignment| null) => {
		api
			.patch(`assignments/${assignment?.id}/power`, assignment)
			.then(() => {
				setCurAssignment(null);
				if (assignment !== null) {
					if (!assignment.siteInfo) {
						assignment.siteInfo = {} as SiteInfo;
					}
					setAssignment(assignment);
					loadAssignment();
				}
			});
	};
	
	const saveWeather = (assignment: Assignment| null) => {
		api
			.patch(`assignments/${assignment?.id}/power`, assignment)
			.then(() => {
				setCurWAssignment(null);
				if (assignment !== null) {
					if (!assignment.weatherInfo) {
						assignment.weatherInfo = {} as WeatherInfo;
					}
					setAssignment(assignment);
					loadAssignment();
				}
			});
	};

	useEffect(() => {
		if (selectedTable && assignment) {
			let tableCandidate =
				assignment.areas[selectedTable.area].rows[selectedTable.row]
					.tables[selectedTable.col];
			if (tableCandidate && tableCandidate !== selectedTable.table) {
				setSelectedTable({ ...selectedTable, table: tableCandidate });
			}
		}
	}, [assignment, setAssignment]);

	let loadAssignment =  () =>
	{
		api.get(`assignments/${id}`)
			.then(({ data }) => {
				let assignment = data;
				if (assignment) {
					setAssignment(assignment);
					api.get(`missions`)
						.then(({ data }) => {
							if (Array.isArray(data)) {
								data.every((mission: Mission) => {
									if (mission.id === assignment.missionId) {
										setMission(mission);
										return false;
									}
									return true;
								});
							}
							setLoading(false);
						})
						.catch((e) => {
							setError(e.toString());
							setLoading(false);
						});
				}
			})
			.catch((e) => {
				setError(e.toString());
				setLoading(false);
			});

	}
	useMount(() => {
		loadAssignment();
	});

	const onViewPowerClick = (assignment: Assignment) => {
		setCurAssignment(assignment);
	};
	const onViewWeatherClick = (assignment: Assignment) => {
		setCurWAssignment(assignment);
	};

	let totalTables = 0;
	let totalPannels = 0;
	let brokenTables = 0;
	let brokenPanels = 0;
	let panelsByProblem: { [key: string]: Losses } = {};

	if (!loading && assignment) {
		assignment.areas.forEach((area) => {
			area.rows.forEach((row) => {
				row.tables.forEach((table) => {
					totalTables++;
					if (table.inspectionStatus !== "Ok" && table.inspectionStatus !== "NotInspected") {
						brokenTables++;
					}
					table.panels.forEach((panel) => {
						if (panel.inspectionStatus !== "Ok" && panel.inspectionStatus !== "NotInspected") {
							brokenPanels++;
						}
						totalPannels++;
					});
				});
			});
		});
		panelsByProblem = getByInspectionStatus(assignment);
	}
	return (
		<>
			{loading ? (
				<Spinner mrgTop="10vh"></Spinner>
			) : error ? (
				<div style={{ width: "100%", textAlign: "center" }}>
					{error}
				</div>
			) : (
				assignment && (
					<div className="container box has-text-centered">
								{curAssignment && (
									<SetPowerPerAssignmentModal
										selectedAssignment={curAssignment}
										saveAssignment={savePower}
										closeModal={() => setCurAssignment(null)}
									></SetPowerPerAssignmentModal>
								)}
								{curWAssignment && (
									<SetWeatherPerAssignmentModal
										selectedAssignment={curWAssignment}
										saveAssignment={saveWeather}
										closeModal={() => setCurWAssignment(null)}
									></SetWeatherPerAssignmentModal>
								)}
						<div
							style={{
								textAlign: "start",
								marginBottom: "2vh",
								display: "flex",
								justifyContent: "space-between",
							}}
						>
							<button
								onClick={() => {
									history.push(
										`/inspections/${assignment.missionId}`
									);
								}}
								className="button is-info is-inverted"
							>
								<FaAngleLeft></FaAngleLeft>Back
							</button>
							<ReportActions
								assignment={assignment}
							></ReportActions>
						</div>
								<Box>
							<table className="table is-fullwidth">
								<thead>
									<tr>
										<th>Park</th>
										<th>Total Tables</th>
										<th>Total Panels</th>
										<th>Damaged Tables</th>
										<th>Damaged Panels</th>
										<th>Time Started</th>
										<th>Time Completed</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>{mission?.name}</td>
										<td>{totalTables}</td>
										<td>{totalPannels}</td>
										<td style={{ color: "crimson" }}>
											{brokenTables}
										</td>
										<td style={{ color: "crimson" }}>
											{brokenPanels}
										</td>
										<td>
											{assignment.timeStarted
												? new Date(
														assignment.timeStarted
												  ).toLocaleDateString("en-GB")
												: ""}
										</td>
										<td>
											{assignment.timeCompleted
												? new Date(
														assignment.timeCompleted
												  ).toLocaleDateString("en-GB")
												: ""}
										</td>
									</tr>
								</tbody>
							</table>
						</Box>
						

						{assignment.areas && assignment.areas.length > 0 && (
							<div
								className={`is-flex  ${
									breakpoints.md
										? "is-flex-direction-column"
										: "is-flex-direction-row"
								}`}
							>
								<div
									className="box"
									style={{
										width: breakpoints.md ? "100%" : "50%",
										marginBottom: "0px",
									}}
								>
									<AssignmentMap
										assignment={assignment}
									></AssignmentMap>
								</div>

								<div
									className="box"
									style={{
										width: breakpoints.md ? "100%" : "50%",
										textAlign: "left",
										marginLeft: breakpoints.md
											? "0px"
											: "10px",
										marginTop: breakpoints.md
											? "10px"
											: "0px",
									}}
								>
									<TableNav
										areas={assignment.areas}
									></TableNav>
									<TableDetails
										imgPrefix={`${TABLE_IMAGES_URL}/${assignment.missionId}/${assignment.id}`}
										updateTable={(table: {
											table: AssignmentTable;
											area: number;
											row: number;
											col: number;
										}) => {
											setLoadingAssignment(true);
											let assignmentCopy: Assignment = {
												...assignment,
												areas: assignment.areas.map(
													(current, index) => {
														if (
															index === table.area
														) {
															let areaCopy = {
																...current,
															};
															areaCopy.rows[
																table.row
															].tables[
																table.col
															] = table.table;
															return areaCopy;
														} else {
															return current;
														}
													}
												),
											};
											api.patch(
												`assignments/${id}`,
												assignmentCopy
											)
												.then(({ data }) => {
													setAssignment(data);
													setLoadingAssignment(false);
												})
												.catch((e) => {
													setLoadingAssignmentError(
														e.toString()
													);
													setLoadingAssignment(false);
												});
										}}
									></TableDetails>
								</div>
							</div>
								)}
								<Box>
									<table className="table is-fullwidth">
										<thead>
											<tr>
												<th>Modules by anomaly type</th>
												<th>Count</th>
												<th>Est. Module Efficiency Loss</th>
												<th>Est. Power Loss<br/>(kW)</th>
												<th>Est. Annual Energy Loss<br/>(kW)</th>
												<th>Est. Annual Revenue Loss<br/>
													{panelsByProblem['TOTAL'].panelPrice} {panelsByProblem['TOTAL'].inCurrency} per kW</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td align="left">Soiling</td>
												<td align="right">{panelsByProblem['PANEL_BROKEN_DIRT'].Count} ({((panelsByProblem['PANEL_BROKEN_DIRT'].Count * 100.00) / totalPannels).toFixed(2)}%)</td>
												<td align="right">{panelsByProblem['PANEL_BROKEN_DIRT'].EffLoss.toFixed(0)}%</td>
												<td align="right">{panelsByProblem['PANEL_BROKEN_DIRT'].PowerLoss.toFixed(2)}</td>
												<td align="right">{panelsByProblem['PANEL_BROKEN_DIRT'].EnergyLoss.toFixed(2)}</td>
												<td align="right">{panelsByProblem['PANEL_BROKEN_DIRT'].RevenueLoss.toFixed(2)}</td>
											</tr>
											<tr>
												<td align="left" >Module Cracking</td>
												<td align="right">{panelsByProblem['PANEL_BROKEN_CRACKING'].Count} ({((panelsByProblem['PANEL_BROKEN_CRACKING'].Count * 100.00) / totalPannels).toFixed(2)}%)</td>
												<td align="right">{panelsByProblem['PANEL_BROKEN_CRACKING'].EffLoss.toFixed(0)}%</td>
												<td align="right">{panelsByProblem['PANEL_BROKEN_CRACKING'].PowerLoss.toFixed(2)}</td>
												<td align="right">{panelsByProblem['PANEL_BROKEN_CRACKING'].EnergyLoss.toFixed(2)}</td>
												<td align="right">{panelsByProblem['PANEL_BROKEN_CRACKING'].RevenueLoss.toFixed(2)}</td>
											</tr>
											<tr>
												<td align="left">Multiple Hot Cells</td>
												<td align="right">{panelsByProblem['PANEL_BROKEN_MULTICELL'].Count} ({((panelsByProblem['PANEL_BROKEN_MULTICELL'].Count * 100.00) / totalPannels).toFixed(2)}%)</td>
												<td align="right">{panelsByProblem['PANEL_BROKEN_MULTICELL'].EffLoss.toFixed(0)}%</td>
												<td align="right">{panelsByProblem['PANEL_BROKEN_MULTICELL'].PowerLoss.toFixed(2)}</td>
												<td align="right">{panelsByProblem['PANEL_BROKEN_MULTICELL'].EnergyLoss.toFixed(2)}</td>
												<td align="right">{panelsByProblem['PANEL_BROKEN_MULTICELL'].RevenueLoss.toFixed(2)}</td>
											</tr>
											<tr>
												<td align="left">Bypass Diode</td>
												<td align="right">{panelsByProblem['PANEL_BROKEN_DIODE'].Count} ({((panelsByProblem['PANEL_BROKEN_DIODE'].Count * 100.00) / totalPannels).toFixed(2)}%)</td>
												<td align="right">{panelsByProblem['PANEL_BROKEN_DIODE'].EffLoss.toFixed(0)}%</td>
												<td align="right">{panelsByProblem['PANEL_BROKEN_DIODE'].PowerLoss.toFixed(2)}</td>
												<td align="right">{panelsByProblem['PANEL_BROKEN_DIODE'].EnergyLoss.toFixed(2)}</td>
												<td align="right">{panelsByProblem['PANEL_BROKEN_DIODE'].RevenueLoss.toFixed(2)}</td>
											</tr>
											<tr>
												<td align="left">Hot cell</td>
												<td align="right">{panelsByProblem['PANEL_BROKEN_CELL'].Count} ({((panelsByProblem['PANEL_BROKEN_CELL'].Count * 100.00) / totalPannels).toFixed(2)}%)</td>
												<td align="right">{panelsByProblem['PANEL_BROKEN_CELL'].EffLoss.toFixed(0)}%</td>
												<td align="right">{panelsByProblem['PANEL_BROKEN_CELL'].PowerLoss.toFixed(2)}</td>
												<td align="right">{panelsByProblem['PANEL_BROKEN_CELL'].EnergyLoss.toFixed(2)}</td>
												<td align="right">{panelsByProblem['PANEL_BROKEN_CELL'].RevenueLoss.toFixed(2)}</td>
											</tr>
											<tr>
												<td align="left">Vegetation/Shadowing</td>
												<td align="right">{panelsByProblem['PANEL_BROKEN_VEGETATION'].Count} ({((panelsByProblem['PANEL_BROKEN_VEGETATION'].Count * 100.00) / totalPannels).toFixed(2)}%)</td>
												<td align="right">{panelsByProblem['PANEL_BROKEN_VEGETATION'].EffLoss.toFixed(0)}%</td>
												<td align="right">{panelsByProblem['PANEL_BROKEN_VEGETATION'].PowerLoss.toFixed(2)}</td>
												<td align="right">{panelsByProblem['PANEL_BROKEN_VEGETATION'].EnergyLoss.toFixed(2)}</td>
												<td align="right">{panelsByProblem['PANEL_BROKEN_VEGETATION'].RevenueLoss.toFixed(2)}</td>
											</tr>
											<tr>
												<td align="left">Sun reflection</td>
												<td align="right">{panelsByProblem['PANEL_BROKEN_SUNREFLECTION'].Count} ({((panelsByProblem['PANEL_BROKEN_SUNREFLECTION'].Count * 100.00) / totalPannels).toFixed(2)}%)</td>
												<td align="right">{panelsByProblem['PANEL_BROKEN_SUNREFLECTION'].EffLoss.toFixed(0)}%</td>
												<td align="right">{panelsByProblem['PANEL_BROKEN_SUNREFLECTION'].PowerLoss.toFixed(2)}</td>
												<td align="right">{panelsByProblem['PANEL_BROKEN_SUNREFLECTION'].EnergyLoss.toFixed(2)}</td>
												<td align="right">{panelsByProblem['PANEL_BROKEN_SUNREFLECTION'].RevenueLoss.toFixed(2)}</td>
											</tr>
											<tr>
												<td align="left">Hot module</td>
												<td align="right">{panelsByProblem['PANEL_BROKEN_MODULE'].Count} ({((panelsByProblem['PANEL_BROKEN_MODULE'].Count * 100.00) / totalPannels).toFixed(2)}%)</td>
												<td align="right">{panelsByProblem['PANEL_BROKEN_MODULE'].EffLoss.toFixed(0)}%</td>
												<td align="right">{panelsByProblem['PANEL_BROKEN_MODULE'].PowerLoss.toFixed(2)}</td>
												<td align="right">{panelsByProblem['PANEL_BROKEN_MODULE'].EnergyLoss.toFixed(2)}</td>
												<td align="right">{panelsByProblem['PANEL_BROKEN_MODULE'].RevenueLoss.toFixed(2)}</td>
											</tr>
											<tr>
												<td align="left">Multiple hot module</td>
												<td align="right">{panelsByProblem['PANEL_BROKEN_MULTIMODULE'].Count} ({((panelsByProblem['PANEL_BROKEN_MULTIMODULE'].Count * 100.00) / totalPannels).toFixed(2)}%)</td>
												<td align="right">{panelsByProblem['PANEL_BROKEN_MULTIMODULE'].EffLoss.toFixed(0)}%</td>
												<td align="right">{panelsByProblem['PANEL_BROKEN_MULTIMODULE'].PowerLoss.toFixed(2)}</td>
												<td align="right">{panelsByProblem['PANEL_BROKEN_MULTIMODULE'].EnergyLoss.toFixed(2)}</td>
												<td align="right">{panelsByProblem['PANEL_BROKEN_MULTIMODULE'].RevenueLoss.toFixed(2)}</td>
											</tr>
											<tr>
												<td align="left">Missing module</td>
												<td align="right">{panelsByProblem['PANEL_BROKEN_MISSING'].Count} ({((panelsByProblem['PANEL_BROKEN_MISSING'].Count * 100.00) / totalPannels).toFixed(2)}%)</td>
												<td align="right">{panelsByProblem['PANEL_BROKEN_MISSING'].EffLoss.toFixed(0)}%</td>
												<td align="right">{panelsByProblem['PANEL_BROKEN_MISSING'].PowerLoss.toFixed(2)}</td>
												<td align="right">{panelsByProblem['PANEL_BROKEN_MISSING'].EnergyLoss.toFixed(2)}</td>
												<td align="right">{panelsByProblem['PANEL_BROKEN_MISSING'].RevenueLoss.toFixed(2)}</td>
											</tr>
											<tr>
												<td align="left">Other problem</td>
												<td align="right">{panelsByProblem['PANEL_BROKEN_OTHER'].Count} ({((panelsByProblem['PANEL_BROKEN_OTHER'].Count * 100.00) / totalPannels).toFixed(2)}%)</td>
												<td align="right">{panelsByProblem['PANEL_BROKEN_OTHER'].EffLoss.toFixed(0)}%</td>
												<td align="right">{panelsByProblem['PANEL_BROKEN_OTHER'].PowerLoss.toFixed(2)}</td>
												<td align="right">{panelsByProblem['PANEL_BROKEN_OTHER'].EnergyLoss.toFixed(2)}</td>
												<td align="right">{panelsByProblem['PANEL_BROKEN_OTHER'].RevenueLoss.toFixed(2)}</td>
											</tr>
											<tr>
												<td align="left">Total</td>
												<td align="right">{panelsByProblem['TOTAL'].Count}</td>
												<td align="right"></td>
												<td align="right">{panelsByProblem['TOTAL'].PowerLoss.toFixed(2)}</td>
												<td align="right">{panelsByProblem['TOTAL'].EnergyLoss.toFixed(2)}</td>
												<td align="right">{panelsByProblem['TOTAL'].RevenueLoss.toFixed(2)}</td>
											</tr>
										</tbody>
									</table>
									<button
										className="button"
										onClick={() => onViewPowerClick(assignment)}
									>
										Edit Power Settings
									</button>
									<button
										className="button"
										onClick={() => onViewWeatherClick(assignment)}
									>
										Edit Weather Settings
									</button>
								</Box>

					</div>
				)
			)}
		</>
	);
};

export default AssignmentView;

import { useAuth } from "customhooks/useAuth";
import { Tenant } from "dtos/Tenant";
import UserRoleGate from "gates/UserRoleGate";
import { enableTenantSwitch, theTenant } from "myrecoil/TenantState";
import logo from "public/images/moonlimit-logo.png";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";

export const NavbarItem = ({
	activeLink,
	page,
	linkClicked,
	linkTo,
}: {
	activeLink: string;
	page: string;
	linkClicked: (s: string) => void;
	linkTo?: string;
}) => {
	return (
		<Link
			className={`navbar-item is-capitalized ${
				activeLink === page.substring(0, page.length - 1) ||
				activeLink === page
					? "is-active"
					: ""
			}`}
			to={linkTo ? linkTo : `/${page}`}
			onClick={() => {
				linkClicked(page);
			}}
		>
			{page}
		</Link>
	);
};

const themes = ["light", "dark", "milestone"];

const NavBar = () => {
	const [themeIndex, setThemeIndex] = useState<number>(0);
	const [activeLink, setActiveLink] = useState<any>("inspections");
	const [isNavToggled, setIsNavToggled] = useState<boolean>(false);
	const [tenant, setTenant] = useRecoilState<Tenant | null>(theTenant);
	const [isSwitchEnabled] = useRecoilState(enableTenantSwitch);

	const auth = useAuth();

	const onToggleNav = () => {
		setIsNavToggled(!isNavToggled);
	};

	const linkClicked = (link: string) => {
		setActiveLink(link);
		setIsNavToggled(false);
	};

	const { pathname } = useLocation();

	useEffect(() => {
		let storedThemeIndex = localStorage.getItem("theme-index");
		if (storedThemeIndex) {
			if (!isNaN(Number(storedThemeIndex))) {
				setThemeIndex(Number(storedThemeIndex));
			}
		}
		let path = "";
		if (pathname.length > 1) {
			let cleanPath = pathname.substr(1).split("/")[0];
			path = cleanPath;
		}
		setActiveLink(path);
	}, [pathname]);

	useEffect(() => {
		if (themeIndex < themes.length) {
			document.documentElement.setAttribute("theme", themes[themeIndex]);
			localStorage.setItem("theme-index", themeIndex.toString());
		}
	}, [setThemeIndex, themeIndex]);

	return (
		<nav
			className="navbar has-shadow is-spaced"
			role="navigation"
			aria-label="main navigation"
		>
			<div className="container">
				<div className="navbar-brand">
					<img
						alt=""
						src={logo}
						style={{
							height: "80px",
							width: "auto",
							marginRight: "10px",
						}}
						onClick={() => {
							setThemeIndex((themeIndex + 1) % themes.length);
						}}
					></img>

					<span
						role="button"
						className={`navbar-burger burger ${
							isNavToggled ? " is-active" : " "
						}`}
						aria-label="menu"
						aria-expanded="false"
						data-target="navBarBasic"
						onClick={onToggleNav}
					>
						<span aria-hidden="true"></span>
						<span aria-hidden="true"></span>
						<span aria-hidden="true"></span>
					</span>
				</div>
				<div
					id="navBarBasic"
					className={`navbar-menu ${
						isNavToggled ? " is-active" : " "
					}`}
				>
					<div className="navbar-start">
						<NavbarItem
							activeLink={activeLink}
							linkClicked={linkClicked}
							page={"inspections"}
							key={"inspections"}
						/>
						<UserRoleGate
							allowedRoles={["operator", "admin", "clientadmin"]}
							AlternativeComponents={() => null}
						>
							<NavbarItem
								activeLink={activeLink}
								linkClicked={linkClicked}
								page={"drones"}
								key={"drones"}
							/>
							<NavbarItem
								activeLink={activeLink}
								linkClicked={linkClicked}
								page={"missions"}
								key={"missions"}
							/>
						</UserRoleGate>
						<UserRoleGate
							allowedRoles={["admin", "clientadmin"]}
							AlternativeComponents={() => null}
						>
							<NavbarItem
								activeLink={activeLink}
								linkClicked={linkClicked}
								page={"users"}
								key={"users"}
							/>
						</UserRoleGate>
					</div>
					<div className="navbar-end">
						<div className="navbar-item">
							<h1 className="title">{tenant?.name}</h1>
						</div>
						{isSwitchEnabled && (
							<div className="navbar-item">
								<div
									className="buttons"
									onClick={() => {
										setTenant(null);
									}}
								>
									<span className="button is-primary">
										<strong>Switch Company</strong>
									</span>
								</div>
							</div>
						)}
						<div className="navbar-item">
							<div
								className="buttons"
								onClick={() => {
									auth.logout();
								}}
							>
								<span className="button is-primary">
									<strong>Log out</strong>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</nav>
	);
};

export default NavBar;

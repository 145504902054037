import { FC } from "react";

interface Props {
	completed: number;
}
const ExportProgressBar: FC<Props> = ({ completed }) => {
	const containerStyles = {
		height: "5px",
		width: "100%",
		backgroundColor: "transparent",
		border: "1px solid black",
	};

	return (
		<div style={containerStyles}>
			<div
				style={{
					height: "100%",
					width: `${completed}%`,
					borderRadius: "inherit",
					textAlign: "right",
					backgroundColor: "blue",
				}}
			></div>
		</div>
	);
};

export default ExportProgressBar;

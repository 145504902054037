import { NavbarItem } from "components/NavBar";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import logo from "public/images/moonlimit-logo.png";
import { useAuth } from "customhooks/useAuth";

const themes = ["light", "dark", "milestone"];

const AdminNavBar: React.FC = () => {
	const { pathname } = useLocation();
	const [activeLink, setActiveLink] = useState<any>("companies");
	const [isNavToggled, setIsNavToggled] = useState<boolean>(false);
	const [themeIndex, setThemeIndex] = useState<number>(0);

	const auth = useAuth();

	useEffect(() => {
		let link =
			pathname.length > 1 && pathname.includes("admin/companies")
				? "companies"
				: "";
		setActiveLink(link);
	}, [pathname]);

	const linkClicked = (link: string) => {
		setActiveLink(link);
		setIsNavToggled(false);
	};

	const onToggleNav = () => {
		setIsNavToggled(!isNavToggled);
	};

	useEffect(() => {
		if (themeIndex < themes.length) {
			document.documentElement.setAttribute("theme", themes[themeIndex]);
			localStorage.setItem("theme-index", themeIndex.toString());
		}
	}, [setThemeIndex, themeIndex]);

	return (
		<nav
			className="navbar has-shadow is-spaced"
			role="navigation"
			aria-label="main navigation"
		>
			<div className="container">
				<div className="navbar-brand">
					<img
						alt=""
						src={logo}
						style={{
							height: "80px",
							width: "auto",
							marginRight: "10px",
						}}
						onClick={() => {
							setThemeIndex((themeIndex + 1) % themes.length);
						}}
					></img>

					<span
						role="button"
						className={`navbar-burger burger ${
							isNavToggled ? " is-active" : " "
						}`}
						aria-label="menu"
						aria-expanded="false"
						data-target="navBarBasic"
						onClick={onToggleNav}
					>
						<span aria-hidden="true"></span>
						<span aria-hidden="true"></span>
						<span aria-hidden="true"></span>
					</span>
				</div>
				<div
					id="navBarBasic"
					className={`navbar-menu ${
						isNavToggled ? " is-active" : " "
					}`}
				>
					<div className="navbar-start">
						<NavbarItem
							activeLink={activeLink}
							linkClicked={linkClicked}
							page="companies"
							key="companies"
							linkTo="admin/companies"
						/>
					</div>
				</div>
				<div className="navbar-end">
					<div className="navbar-item">
						<div
							className="buttons"
							onClick={() => {
								auth.logout();
							}}
						>
							<span className="button is-primary">
								<strong>Log out</strong>
							</span>
						</div>
					</div>
				</div>
			</div>
		</nav>
	);
};

export default AdminNavBar;

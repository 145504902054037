import { useApi } from "api/API";
import ProgressBar from "components/ProgressBar";
import useInterval from "customhooks/useInterval";
import { useReportDownload } from "customhooks/useReportDownload";
import { useState } from "react";
import { FaCaretDown, FaDownload } from "react-icons/fa";
import ExportProgressBar from "./ExportProgressBar";

interface Props {
	reportName: string;
	assignmentId: string;
	generateImgs: boolean;
}

interface Progress {
	processingPage: number;
	pageCount: number;
	state: string;
}

const ReportExportComponent: React.FC<Props> = ({
	reportName,
	assignmentId,
	generateImgs: generateIRImgs,
}) => {
	const [isActive, setIsActive] = useState<boolean>(false);
	const [token, setToken] = useState<string | null>();
	const [progress, setProgress] = useState<Progress | null>(null);

	useInterval(
		() => {
			api.get(`reports/downloadstatus?token=${token}`).then(
				({ data }) => {
					let pr = data as Progress;
					if (
						"NotActive" === pr.state ||
						pr.processingPage < progress!.processingPage
					) {
						setProgress(null);
					} else {
						setProgress(pr);
					}
				}
			);
		},
		progress ? 3000 : null
	);

	const api = useApi();
	const download = useReportDownload();

	const exportTo = (to: string) => {
		api.get(
			`reports/${reportName}/export?to=${to}&assignmentId=${assignmentId}`
		).then(({ data }) => {
			download.download(data);
			setToken(data);
			setProgress({
				processingPage: 0,
				pageCount: 100,
				state: "InProgress",
			});
			setIsActive(false);
		});
	};

	return (
		<>
			<div
				className={`dropdown ${
					isActive ? "is-active " : ""
				}dropdown-content-arrow`}
			>
				<div className="dropdown-trigger">
					<button
						className="button is-success is-light is-outlined"
						aria-haspopup="true"
						aria-controls="dropdown-menu"
						onClick={() => setIsActive((val) => !val)}
						style={{ paddingRight: "5px" }}
					>
						<FaDownload style={{ marginRight: "5px" }}></FaDownload>
						Download Report
						<FaCaretDown style={{ marginLeft: "5px" }}></FaCaretDown>
					</button>
				</div>
				<div
					className="dropdown-menu"
					id="dropdown-menu"
					role="menu"
					onBlur={() => setIsActive(false)}
				>
					<div className="dropdown-content">
						<a
							href="#"
							className="dropdown-item"
							onClick={() => exportTo("pdf")}
						>
							PDF
						</a>
						<a
							href="#"
							className="dropdown-item"
							onClick={() => exportTo("xlsx")}
						>
							XLSX
						</a>
						<a
							href="#"
							className="dropdown-item"
							onClick={() => exportTo("docx")}
						>
							DOCX
						</a>
					</div>
				</div>
			</div>
			{progress && (
				<div style={{ margin: "0px" }}>
					<p style={{ fontSize: "0.7em" }}>Generating Report...</p>
					<div
						style={{
							width: "12em",
							position: "absolute",
						}}
					>
						<ExportProgressBar
							completed={progress.processingPage}
						></ExportProgressBar>
					</div>
				</div>
			)}
		</>
	);
};

export default ReportExportComponent;

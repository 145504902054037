import EditableParagraph from "components/EditableParagraph";
import useMount from "customhooks/useMount";
import { AssignmentArea } from "dtos";
import { selectedTable as selectedTableAtom } from "myrecoil/AssignmentState";
import { FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";
import { useRecoilState } from "recoil";

interface Props {
	areas: AssignmentArea[];
}

const TableNav: React.FC<Props> = ({ areas }) => {
	const [selectedTable, setSelectedTable] = useRecoilState(selectedTableAtom);

	useMount(() => {
		if (!selectedTable) {
			prev();
		}
	});

	const prev = () => {
		if (!selectedTable) {
			setSelectedTable({
				table: areas[0].rows[0].tables[0],
				area: 0,
				row: 0,
				col: 0,
			});
		} else {
			let area = areas[selectedTable.area];
			if (selectedTable.col === 0) {
				let areaIndex = selectedTable.area;
				let nextRowIdx;
				if (selectedTable.row === 0) {
					if (areas.length > 1) {
						areaIndex =
							areaIndex === 0 ? areas.length - 1 : areaIndex - 1;
						area = areas[areaIndex];
					}
					nextRowIdx = area.rows.length - 1;
				} else {
					nextRowIdx = selectedTable.row - 1;
				}
				let nextRow = area.rows[nextRowIdx];
				setSelectedTable({
					table: nextRow.tables[nextRow.tables.length - 1],
					area: areaIndex,
					row: nextRowIdx,
					col: nextRow.tables.length - 1,
				});
			} else {
				setSelectedTable({
					table: area.rows[selectedTable.row].tables[
						selectedTable.col - 1
					],
					area: selectedTable.area,
					row: selectedTable.row,
					col: selectedTable.col - 1,
				});
			}
		}
	};

	const next = () => {
		if (!selectedTable) {
			setSelectedTable({
				table: areas[0].rows[0].tables[0],
				area: 0,
				row: 0,
				col: 0,
			});
		} else {
			let area = areas[selectedTable.area];
			if (
				selectedTable.col ===
				area.rows[selectedTable.row].tables.length - 1
			) {
				let areaIndex = selectedTable.area;
				let nextRowIdx;
				if (selectedTable.row === area.rows.length - 1) {
					if (areas.length > 1) {
						areaIndex =
							areaIndex === areas.length - 1 ? 0 : areaIndex + 1;
						area = areas[areaIndex];
					}
					nextRowIdx = 0;
				} else {
					nextRowIdx = selectedTable.row + 1;
				}
				setSelectedTable({
					table: area.rows[nextRowIdx].tables[0],
					area: areaIndex,
					row: nextRowIdx,
					col: 0,
				});
			} else {
				setSelectedTable({
					table: area.rows[selectedTable.row].tables[
						selectedTable.col + 1
					],
					area: selectedTable.area,
					row: selectedTable.row,
					col: selectedTable.col + 1,
				});
			}
		}
	};

	const setRow = (rowStr: string) => {
		let area = areas[selectedTable!.area];
		try {
			let rowIdx = Number.parseInt(rowStr) - 1;
			if (rowIdx >= 0 && rowIdx < area.rows.length) {
				let row = area.rows[rowIdx];
				let colIdx =
					row.tables.length > selectedTable!.col
						? selectedTable!.col
						: 0;
				setSelectedTable({
					table: row.tables[colIdx],
					area: selectedTable!.area,
					row: rowIdx,
					col: colIdx,
				});
				return true;
			}
		} catch (e) {}
		return false;
	};

	const setCol = (colStr: string) => {
		let area = areas[selectedTable!.area];
		try {
			let colIdx = Number.parseInt(colStr) - 1;
			if (
				colIdx >= 0 &&
				colIdx < area.rows[selectedTable!.row].tables.length
			) {
				setSelectedTable({
					table: area.rows[selectedTable!.row].tables[colIdx],
					area: selectedTable!.area,
					row: selectedTable!.row,
					col: colIdx,
				});
				return true;
			}
		} catch (e) {}
		return false;
	};

	const setArea = (areaStr: string) => {
		try {
			let areaIdx = Number.parseInt(areaStr) - 1;
			if (
				areaIdx >= 0 &&
				areaIdx < areas.length
			) {
				setSelectedTable({
					table: areas[areaIdx].rows[0].tables[0],
					area: areaIdx,
					row: 0,
					col: 0,
				});
				return true;
			}
		} catch (e) {}
		return false;
	}

	return (
		<div
			style={{
				display: "flex",
				alignItems: "center",
				marginBottom: "1em",
			}}
		>
			<button
				onClick={prev}
				className="button is-info is-inverted"
				title="Prev Table"
			>
				<FaAngleDoubleLeft></FaAngleDoubleLeft>
			</button>
			<div
				style={{
					fontSize: "1.25rem",
					margin: "0 auto",
					display: "inline-block",
				}}
			>
				{selectedTable && (
					<div>
						<b>Area: </b>
						<EditableParagraph
							value={selectedTable.area + 1 + ""}
							setValue={setArea}
						></EditableParagraph>
						<b>Row: </b>
						<EditableParagraph
							value={selectedTable.row + 1 + ""}
							setValue={setRow}
						></EditableParagraph>
						<b>Table: </b>
						<EditableParagraph
							value={selectedTable.col + 1 + ""}
							setValue={setCol}
						></EditableParagraph>{" "}
					</div>
				)}
			</div>
			<button
				onClick={next}
				className="button is-info is-inverted"
				title="Next Table"
			>
				<FaAngleDoubleRight></FaAngleDoubleRight>
			</button>
		</div>
	);
};

export default TableNav;

import { Constants } from "helpers/Constants";
import ko from "knockout";
import { useEffect, useRef } from "react";
import "devexpress-reporting/dx-webdocumentviewer";
import { useRecoilValue } from "recoil";
import { theTenant } from "myrecoil/TenantState";
import { userAtom } from "myrecoil/UserState";

interface Props {
	reportName: string;
	assignmentId: string;
	isLight: boolean;
}

const ReportViewer: React.FC<Props> = ({ reportName, assignmentId, isLight }) => {
	const viewer = useRef<any>();
	const tenant = useRecoilValue(theTenant);
	const user = useRecoilValue(userAtom);
	
	useEffect(() => {
		ko.applyBindings(
			{
				reportUrl: `${reportName}?tenantId=${tenant?.id}&assignmentId=${assignmentId}&userId=${user?.sub}`,
				requestOptions: {
					host: Constants.apiRoot + "/",
					invokeAction: "DXXRDV",
				},
			},
			viewer.current
		);
		return () => {
			ko.cleanNode(viewer.current);
		};
	}, [assignmentId]);

	return <div ref={viewer} data-bind="dxReportViewer: $data"></div>;
};

export default ReportViewer;

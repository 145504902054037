import { Assignment } from "dtos";
import { useState } from "react";
import { FaBook } from "react-icons/fa";
import ReportExportComponent from "./ReportExportComponent";

interface Props {
	assignment: Assignment;
}

const ReportActions: React.FC<Props> = ({ assignment }) => {
	const [isGenerateImgs, setIsGenerateImgs] = useState<boolean>(false);

	return (
		<div
			style={{
				float: "right",
				display: "flex",
			}}
		>
			<div>
				<div style={{ margin: "auto" }}></div>
					<div style= {{ paddingTop:"30px"}}>
					<a
						href={`/inspection/report/view?reportName=LomaReport&id=${assignment.id}&isLight=false`}
						target="_blank"
						rel="noreferrer noopener"
						className="button is-info is-light is-outlined"
						style={{ marginRight: "5px" }}
					>
						<FaBook style={{ marginRight: "5px" }}></FaBook>View Report
					</a>
					<ReportExportComponent
						reportName="LomaReport"
						assignmentId={assignment.id}
						generateImgs={false}
					></ReportExportComponent>
				</div>
			</div>
    	</div>
);
};

export default ReportActions;

import { Area } from "dtos";
import { AreaTypes, getAreaColor } from "helpers/MissionAreaUtils";
import { useEffect, useState } from "react";

interface Props {
	targetArea: Area;
	updateArea: (newArea: Area, editedArea: Area) => void;
}

const AreaAltitudeComponent: React.FC<Props> = ({ targetArea, updateArea }) => {
	const [max, setMax] = useState<string>(targetArea.maxAltitude + "");
	const [min, setMin] = useState<string>(targetArea.minAltitude + "");
	const [target, setTarget] = useState<string>(
		targetArea.targetAltitude + ""
	);
	useEffect(() => {
		setMax(targetArea.maxAltitude + "");
		setMin(targetArea.minAltitude + "");
		setTarget(targetArea.targetAltitude + "");
	}, [targetArea]);
	let viewObj = null;
	if (
		targetArea.kind === AreaTypes.MISSION.num ||
		targetArea.kind === AreaTypes.MISSION.str
	) {
		viewObj = {
			targetAltitudeLabel: "Mission Altitude",
			minAltitudeLabel: "Mission Min Altitude",
			maxAltitudeLabel: "Mission Max Altitude",
			bgColor: "inherit",
		};
	} else if (
		targetArea.kind === AreaTypes.AVOID.num ||
		targetArea.kind === AreaTypes.AVOID.str
	) {
		viewObj = {
			targetAltitudeLabel: "Avoid Area Altitude",
			minAltitudeLabel: "Avoid Area Min Altitude",
			maxAltitudeLabel: "Avoid Area Max Altitude",
			bgColor: getAreaColor(targetArea.kind),
		};
	} else {
		viewObj = {
			targetAltitudeLabel: "Interest Area Altitude",
			minAltitudeLabel: "Interest Area Min Altitude",
			maxAltitudeLabel: "Interest Area Max Altitude",
			bgColor: getAreaColor(targetArea.kind),
		};
	}
	return (
		<>
			<div style={{ minWidth: "266px" }} className="field">
				<label className="label">{viewObj.targetAltitudeLabel}: </label>
				<input
					className="input "
					type="number"
					style={{ backgroundColor: viewObj.bgColor }}
					value={target}
					placeholder={viewObj.targetAltitudeLabel}
					onChange={(event) => {
						if (event.target.value !== "") {
							let num = Number(event.target.value);
							updateArea(
								{ ...targetArea, targetAltitude: num },
								targetArea
							);
						} else {
							setTarget("");
						}
					}}
				></input>
			</div>
			<div style={{ minWidth: "266px" }} className="field">
				<label className="label">{viewObj.minAltitudeLabel}: </label>
				<input
					className="input "
					type="number"
					style={{ backgroundColor: viewObj.bgColor }}
					value={min}
					placeholder={viewObj.minAltitudeLabel}
					onChange={(event) => {
						if (event.target.value !== "") {
							let num = Number(event.target.value);
							updateArea(
								{ ...targetArea, minAltitude: num },
								targetArea
							);
						} else {
							setMin("");
						}
					}}
				></input>
			</div>
			<div style={{ minWidth: "266px" }} className="field">
				<label className="label">{viewObj.maxAltitudeLabel}: </label>
				<input
					className="input "
					type="number"
					style={{ backgroundColor: viewObj.bgColor }}
					value={max}
					placeholder={viewObj.maxAltitudeLabel}
					onChange={(event) => {
						if (event.target.value !== "") {
							let num = Number(event.target.value);
							updateArea(
								{ ...targetArea, maxAltitude: num },
								targetArea
							);
						} else {
							setMax("");
						}
					}}
				></input>
			</div>
		</>
	);
};

export default AreaAltitudeComponent;

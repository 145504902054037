import { Area, Point } from "dtos";
import React, { useEffect, useRef } from "react";
import { Polygon } from "@react-google-maps/api";

interface Props {
  area: Area;
  color: string;
  setPath: (points: Point[], area: Area) => void;
  addRef: (area: Area, polygon: any) => void;
  removeRef: (polygon: any) => void;
  focused?: boolean;
  onClick?: (event: any) => void;
}

const AreaView: React.FC<Props> = ({
  area,
  color,
  setPath,
  addRef,
  focused,
  removeRef,
  onClick,
}) => {
  const polygonRef = useRef<any>(null);

  const onLoad = (polygon: any) => {
    polygonRef.current = polygon;
    addRef(area, polygon);
  };

  const onEdit = () => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map((latLng: any) => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });
      setPath(nextPath, area);
    }
  };

  useEffect(() => {
    return () => {
      removeRef(polygonRef.current);
    };
  }, []);

  return (
    <Polygon
      editable={focused}
      draggable={focused}
      path={area.points}
      onLoad={onLoad}
      options={{
        fillColor: color,
        strokeColor: color,
        clickable: focused,
      }}
      onClick={onClick}
      onMouseUp={focused ? onEdit : undefined}
      onDragEnd={focused ? onEdit : undefined}
    />
  );
};

export default AreaView;
